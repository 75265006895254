<template>
  <el-dialog
    :title="todo == 'add' ? '【新增】应收报价模板' : (todo == 'edit' ? '【修改】应收报价模板' : '【查看】应收报价模板')"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="模板名称" prop="templateName">
            <el-input v-model="dataForm.templateName" placeholder="模板名称" :disabled="todo == 'view' ? true : false"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="模板编号" prop="templateCode">
            <el-input v-model="dataForm.templateCode"  placeholder="模板编号" :disabled="todo == 'view' ? true : false"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="12">
          <el-form-item label="订单类型" prop="orderType">
            <el-select class="item-choose" v-model="dataForm.orderType" style="width: 100%;" filterable clearable placeholder="请选择" :disabled="todo == 'view' ? true : false">
              <el-option
                v-for="item in dictTypeMap.orderType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="50">
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" :rows="5" v-model="dataForm.remark" placeholder="备注" :disabled="todo == 'view' ? true : false"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        templateCode: null,
        templateName: null,
        orderType: null,
        templateType: 1,
        remark: null
      },
      todo: 'view',
      dataRule: {
        templateName: [
          { required: true, message: '模板名称不能为空', trigger: 'blur' }
        ],
        templateCode: [
          { required: true, message: '模板编号不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    init (todo, id) {
      this.dataForm.id = id
      this.visible = true
      this.todo = todo
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/priceTemplate/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.templateName = data.templateName
            this.dataForm.orderType = data.orderType
            this.dataForm.templateCode = data.templateCode
            this.dataForm.remark = data.remark
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/priceTemplate/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'templateName': this.dataForm.templateName,
              'templateCode': this.dataForm.templateCode,
              'orderType': this.dataForm.orderType,
              'templateType': this.dataForm.templateType,
              'remark': this.dataForm.remark
            })
          }).then(({data}) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
